import { z } from 'zod';

export type EnvironmentVariable = z.infer<typeof envSchame>;

const envSchame = z.object({
  environment: z.enum(['production', 'staging', 'development', 'test', 'debug']),
  applicationMode: z.enum(['working', 'under_maintenance']),
  maintenanceMessage: z.string().optional(),
  apiBaseUrl: z.string().min(1),
  loginUrl: z.string().min(1),
  kenallPublicKey: z.string().min(1),
  hubspotTrackingScript: z.string().min(1),
  salesforce: z.object({
    trackingHost: z.string().min(1),
    pid: z.string().min(1),
  }),
  dataDogRUM: z.object({
    applicationId: z.string().min(1),
    clientToken: z.string().min(1),
    service: z.string().min(1),
  }),
});

export const publicEnv = envSchame.parse({
  environment: process.env.NEXT_PUBLIC_ENV,
  applicationMode: process.env.NEXT_PUBLIC_APPLICATION_MODE,
  maintenanceMessage: process.env.NEXT_PUBLIC_MAINTENANCE_MESSAGE,
  apiBaseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
  loginUrl: process.env.NEXT_PUBLIC_LOGIN_URL,
  kenallPublicKey: process.env.NEXT_PUBLIC_KENALL_PUBLIC_KEY,
  hubspotTrackingScript: process.env.NEXT_PUBLIC_HUBSPOT_TRACKING_SCRIPT,
  salesforce: {
    trackingHost: process.env.NEXT_PUBLIC_SALESFORCE_TRACKING_HOST,
    pid: process.env.NEXT_PUBLIC_SALESFORCE_PID,
  },
  dataDogRUM: {
    // NOTE: 環境変数で切り替えることはないのでベタ書き
    applicationId: 'a9da1eba-b0ef-4ad6-bbc2-6c2d15b30895',
    clientToken: 'pub3030751464e6eed306582ac5dfc60f27',
    service: 'timee-register-web',
  },
});

export const validateEnvironmentVariable = () => {};
